<template>
  <div class="container">
    <el-select class="option" v-model="current" @change="optionChange" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <div class="feedbacklist">
      <h4>User Feedback</h4>
      <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick">Export</el-button>
      <div class="list">
        <div class="itemview" v-for="(feed) in feedbacks" :key="feed.index">
          <el-avatar shape="square" :size="50" :src="feed.url"></el-avatar>
          <div class="content">
            <div class="weight">
              <span>{{feed.name}}</span>
              <span v-if="feed.type==6" style="color: #f74769;">（Delete Account）</span>
            </div>
            <div class="weight">{{feed.text}}</div>
            <div v-if="feed.photos.length" class="img-wrap">
              <el-image class="img" v-for="(url, index) in feed.photos" :key="index"
                fit="cover"
                :src="url"
                :preview-src-list="feed.photos">
              </el-image>
            </div>
            <div class="bottom">
              <div>
                <div>{{feed.time}}</div>
                <div class="id-wrap">
                  <el-button @click="userIdClick(feed.user_id)" type="text">User ID: {{feed.user_id}}</el-button>
                  <el-button class="copy-btn" icon="el-icon-copy-document" circle @click="copyUserId(feed.user_id)"></el-button>
                </div>
                <div>Category: {{feed.category}}</div>
              </div>
              <div>
                <div v-if="feed.email">Email: {{feed.email}}</div>
                <div>System: {{feed.system}}</div>
                <div v-if="feed.star>0" class="rate">
                  Rate:
                  <el-rate
                    v-model="feed.star"
                    disabled>
                  </el-rate>
                </div>
              </div>
              <div>
                <div>App Verison: {{feed.app_version}}</div>
                <div>Device Version: {{feed.device_version}}</div>
                <div>timezone: {{feed.timezone}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination :onlineList="tableData" @changepage="changePage" @changesize="changeSize"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/cubbily/Pagination';
import {feedbackList, exportReq} from '@/api/cubbily.js';
import moment from 'moment';
import app from '@/config/app.js';

export default {
  name: 'UserFeedback',
  data () {
    return {
      options: [
        {
          value: 1,
          label: 'Contact Us'
        }, {
          value: 7,
          label: '5 Star'
        }, {
          value: 6,
          label: 'Delete Account'
        }
      ],
      current: 1,
      feedbacks: [],
      lastTime: 0,
      tableData: {
        pageSize: 30,
        pageNum: 1,
        total: 10
      },
      exportReqData: {}
    };
  },
  components: {
    pagination: Pagination
  },
  methods: {
    optionChange (e) {
      this.current = e;
      this.getFeedbackList();
    },
    getFeedbackList () {
      let data = {
        page: this.tableData.pageNum,
        limit: this.tableData.pageSize,
        type: this.current
      };
      feedbackList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          let list = res.data.feedbacks;
          console.log('getFeedbackList:', list);
          if (list.length) {
            let lastTime = list[list.length - 1].time;
            // list.forEach((item) => {
            //   item.time = moment(item.time).format('YYYY-MM-DD HH:mm:ss');
            // });
            this.lastTime = lastTime;
          }
          this.feedbacks = list;
          this.tableData.total = res.data.total;
          this.exportReqData = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getFeedbackList：', error);
          this.$message.error(error.error_msg);
        });
    },
    exportClick () {
      exportReq({
        url: '/v2/feedback/export',
        fileName: 'userFeedback',
        data: {
          ...this.exportReqData
        }
      });
    },
    changeSize (size) {
      if (size) {
        this.tableData.pageSize = size;
        this.tableData.pageNum = 1;
        this.getFeedbackList();
      }
    },
    changePage (current) {
      if (current) {
        this.tableData.pageNum = current;
        this.getFeedbackList();
      }
    },
    userIdClick (userId) {
      this.$router.push({
        name: 'UserInfo',
        params: { searchId: userId }
      });
    },
    copyUserId (userId) {
      navigator.clipboard.writeText(userId)
        .then(() => {
          this.$message({
            message: 'Text copied successfully',
            type: 'success'
          });
        });
    }
  },

  beforeCreate () {},
  created () {
    this.getFeedbackList();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {
  },
  destroyed () {}
};
</script>
<style lang="scss">
.container {
  width: calc(100% - 24px);
  height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;
  background: #fff;
  position: relative;
  .option{
    margin-bottom: 20px;
    width: 300px;
  }
  .feedbacklist{
    height: 100%;
    h4{
      margin-bottom: 10px;
      float: left;
    }
    .btn-export{
      /* margin: 50px 0 10px; */
      padding: 0;
      position: absolute;
      right: 24px;
    }
    .list{
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      height: calc(100% - 50px);
      background-color: #FFFFFF;
      font-size: 14px;
      color: #606266;
      overflow-y: scroll;
      border-top: 1px solid #EBEEF5;
      .itemview{
        border-bottom: 1px solid #EBEEF5;
        padding: 12px 24px 12px 0;
        box-sizing: border-box;
        display: flex;
        .content{
          width: 100%;
          margin-left: 12px;
          line-height: 24px;
          .weight{
            font-weight: 500;
          }
          .img-wrap{
            margin-top: 10px;
            .img{
              width: 100px;
              height: 100px;
              margin-right: 10px;
            }
          }
          .bottom{
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            color: #999;

            .id-wrap{
              display: flex;
              align-items: center;

              .copy-btn{
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .rate{
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.el-pagination{
  text-align: center;
  margin-top: 10px;
}
</style>
